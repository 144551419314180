.pp_select_specification {
    display: inline-block;
    width: 40%;
    .select2-container--default {
        width: 100% !important;
        .select2-selection--single {
            height: 35px;
            line-height: 15px;
            .selection__rendered, .select2-selection__rendered {
                line-height: 18px;
            }
        }
    }
}
.pp_select_specification_unit {
    display: inline-block;
    width: 57%;
}

.select2 {
    width: 100%!important;
    margin: 0!important;
}