/* Tags */

div.tagsinput {
  padding: 15px 15px 10px;
  border-color: $border-color;
  background: $card-bg;
  color: $text-muted;
  span {
    &.tag {
      background: theme-color(primary);
      border: 0;
      color: color(white);
      padding: 6px 14px;
      font-size: .8125rem;
      font-family: inherit;
      line-height: 1;
      a {
        color: color(white);
      }
    }
  }
  div {
    &#tags_addTag {
      input {
        @extend .text-muted;
      }
    }
  }
}

.rtl {
  div.tagsinput {
    span.tag,
    div {
      float: right;
    }
  }
}