.relative-list {
    position: relative;
    padding: 0 0 0 15px;
    li {
        list-style: none;
        position: relative;
        margin: 12px 0;
        padding: 0 0 0 10px;
        cursor: pointer;
        color: #10b759;
        &::after {
            position: absolute;
            content: "";
            width: 11px;
            height: 11px;
            background: #10b759;
            border-radius: 50%;
            top: 4px;
            left: -16px;
        }
        &::before {
            position: absolute;
            content: "";
            width: 2px;
            background: #888d9a;
            height: calc(100% + 10px);
            top: 12px;
            left: -11px;
        }
        ul {
            margin-top: 8px;
            &::before {
                position: absolute;
                content: "";
                height: 2px;
                width: 50px;
                background: #888d9a;
                top: 41px;
                left: -11px;
            }
        }
        &.concept {
            color: orange;
            &::after {
                background: orange;
            }
        }
        &.hidden {
            color: #888d9a;
            &::after {
                background: #888d9a;
            }
        }
        &.login {
            color: #727cf5;
            &::after {
                background: #727cf5;
            }
        }
        &:last-child {
            &.hide-line {
                &::before {
                    display: none;
                }
            }
            &::before {
                height: 30px;
            }
        }
    }
}

.menu-structure-legend {
    position: absolute;
    right: 38px;
    top: 10px;
    li {
        list-style: none;
        position: relative;
        margin: 12px 0;
        padding: 0 0 0 10px;
        cursor: pointer;
        color: #10b759;
        text-align: right;
        font-size: 12px;
        &::after {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            background: #10b759;
            border-radius: 50%;
            top: 4px;
            right: -16px;
        }
        &.hidden {
            color: #888d9a;
            &::after {
                background: #888d9a;
            }
        }
        &.locked {
            color: #727cf5;
            &::after {
                background: #727cf5;
            }
        }
        &.concept {
            color: orange;
            &::after {
                background: orange;
            }
        }
        &.disabled {
            color: #ff3366;
            &::after {
                background: #ff3366;
            }
        }
    }
}
