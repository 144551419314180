.fc {
  .fc-button {
    @extend .btn;
  }
  .fc-toolbar {
    &.fc-header-toolbar {
      margin-bottom: 2rem;
      @media(max-width: 767px) {
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        @include flex-wrap(wrap);
        .fc-left {
          margin-bottom: 15px;
        }
        .fc-right {
          margin-bottom: 15px;
        }
      } 
      .fc-left, .fc-right {
        .fc-button-group {
          @extend .btn-group;
          .fc-button {
            @extend .btn-outline-primary;
            background-image: none;
            text-transform: capitalize;
            text-shadow: none;
            &.fc-state-disabled {
              @extend .btn-outline-primary-muted;              
            }
            &.fc-state-active {
              @extend .btn-outline-primary-muted;
            }
            .fc-icon {
              &::after {
                font-family: feather;
                font-size: 16px;
                top: 0;
              }
              &.fc-icon-left-single-arrow {
                &::after {
                  content: "\e843";
                }
              }
              &.fc-icon-right-single-arrow {
                &::after {
                  content: "\e844";
                }
              }
              &.fc-state-disabled {
                &:disabled {
                  text-transform: capitalize;
                  line-height: 0;
                }
              }
            }
          }
        }
      }
      .fc-center {
        h2 {
          font-size: 26px;
        }
      }
    }
  }
  .fc-view-container {
    .fc-view {
      .fc-popover {
        @extend .popover;
        .fc-header {
          background: lighten($card-bg, 5%);
        }
      }
      table {
        .fc-head {
          tr {
            td {
              border-color: $border-color;
              .fc-row {
                table {
                  tr {
                    th {
                      border-color: $border-color;
                    }
                  }
                }
              }
            }
          }
        }
        .fc-body {
          .fc-widget-content {
            border-color: $border-color;
            .fc-day-grid-container {
              .fc-day-grid {
                .fc-row {
                  .fc-bg {
                    table {
                      td {
                        border-color: $border-color;
                        &.fc-other-month {
                          background-color: $card-bg;
                        }
                        &.fc-today {
                          background-color: rgba(theme-color(info), .2);
                        }
                      }
                    }
                  }
                  .fc-content-skeleton {
                    table {
                      .fc-day-top {
                        &.fc-today {
                          background: 0 0;
                          .fc-day-number {
                            padding: 3px 7px;
                            background: theme-color(primary);
                            color: $white;
                            font-weight: bold;
                          }
                        }
                      }
                      .fc-event-container {
                        .fc-day-grid-event {
                          border-radius: 0;
                          padding: 3px 0 3px 5px;
                          border-width: 2px;
                          border-right: 0;
                          border-top: 0;
                          border-bottom: 0;
                          &.fc-event {
                            background-color: rgba(theme-color(danger), .1);
                            border-color: theme-color(danger);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.external-events {
  .fc-event {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 2px;
    background: rgba(theme-color(secondary), .13);
    border: 0;
    border-left: 3px solid theme-color(primary);
    color: $text-muted;
    font-weight: bold;
    font-size: 13px;
    &:nth-child(2) {
      border-left: 3px solid theme-color(warning);
      background: rgba(theme-color(warning), .15);
    }
    &:nth-child(3) {
      border-left: 3px solid theme-color(danger);
      background: rgba(theme-color(danger), .13);
    }
    &:nth-child(4) {
      border-left: 3px solid theme-color(info);
      background: rgba(theme-color(info), .13);
    }
    &:nth-child(5) {
      border-left: 3px solid theme-color(success);
      background: rgba(theme-color(success), .13);
    }
    &:nth-child(6) {
      border-left: 3px solid theme-color(primary);
      background: rgba(theme-color(primary), .13);
    }
  }
}