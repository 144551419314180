.invoice-table, .document-table, .doc-table {
    tr {
        td,
        th {
            padding: 7px 15px;
            min-height: 35px;
            .form-group {
                display: inline;
                margin: 0;
                padding: 0;
            }
            input[type="text"] {
                border: none;
                border-radius: 0;
                background: #060c17 !important;
                &:disabled {
                    background: #070d19 !important;
                    color: #384c6f !important;
                }
            }
            &.td-input {
                padding: 0;
            }
            &.td-disable {
                border-bottom: 1px solid #161d2b;
                border-left: 1px solid #161d2b;
            }
            &.currency-euro {
                position: relative;
                &:after {
                    content: "â¬";
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: 7px;
                    color: #2b3d5d;
                }
            }
            &.td-total,
            &.td-total input[type="text"],
            &.td-total input[type="text"]:disabled {
                color: #10b759 !important;
                font-weight: bold !important;
                &.currency-euro:after {
                    color: #0a6532 !important;
                    font-weight: bold !important;
                }
            }
        }
    }
    .select2-container--default .select2-selection--single {
        border: none;
        margin: 0;
        background: #050a13;
        height: 35px;
        line-height: 15px;
        padding-top: 11px;
    }
}
