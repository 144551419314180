body {
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: theme-color(dark);
    }
    &::-webkit-scrollbar-thumb {
        background: $card-bg;
    }
}

.select2-results__options {
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: theme-color(dark);
    }
    &::-webkit-scrollbar-thumb {
        background: $card-bg;
    }
}

pre {
    background-color: black;
    padding: 15px;
    font-size: 14px;
    color: #717171;
    border-radius: 5px;
}

.paperplane_logo_container {
    img {
        height: 30px;
        width: auto;
        max-width: 130px;
    }
}

input,
select,
textarea,
.select2-selection__rendered {
    color: #8991f5 !important;
}

.select2-selection {
    height: 35px;
    padding-top: 10px;
}

.tt-suggestion {
    color: #070d19;
}

.pp_reset_formfield {
    display: none;
    position: absolute;
    right: 16px;
    bottom: 5px;
    color: #394661;
}

.idle_value {
    opacity: 0.7;
    color: #8991f5 !important;
    i {
        opacity: 0.3;
        color: white !important;
    }
}

.twitter-typeahead {
    width: 100%;
}

.tagsinput {
    border-radius: 3px;
    height: 200px !important;
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: theme-color(dark);
    }
    &::-webkit-scrollbar-thumb {
        background: $card-bg;
    }
}

.table_full_width {
    thead,
    tbody {
        tr {
            td:first-child,
            th:first-child {
                padding-left: 0 !important;
            }
            td:last-child,
            th:last-child {
                padding-right: 0 !important;
                text-align: right !important;
            }
        }
    }
}

.ace_scrollbar {
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #7987a1;
    }
}

.paperplane_inline_flag {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 1px solid #556b96;
    vertical-align: sub;
    margin: 0 6px 0 0;
}

.table-responsive {
    display: none;
    overflow-y: hidden;
}

.header_statistic_container {
    margin: 0 30px 0 0;
}

.loading {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.67);
    z-index: 10000;
    text-align: center;
    height: 100%;
    padding: calc(25% - 20px) 0 0 0;
    .loading-text {
        font-size: 16px;
        position: relative;
        margin: 0 0 0 16px;
        top: -6px;
    }
    .loading-spinner {
        vertical-align: middle;
    }
}
